import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
import ArrowIcon from '../components/icons/ArrowIcon'

const illustration = ({ data }) => {
  const {
    illustration_title,
    illustration_caption,
    illustration_still,
    section,
  } = data.strapiIllustration

  let sectionChapter
  data.allStrapiChapter.nodes.forEach(chapter => {
    if (chapter.strapiId === section.chapter) {
      sectionChapter = chapter
    }
  })

  console.log(data)

  const sectionLink = `/${sectionChapter.chapter_slug}/${section.section_slug}/`

  return (
    <Layout>
      <SEO title={illustration_title} image={illustration_still} />
      <TopNav />
      <div className={`pt64 backgroundBlack`}>
        <div className={`pageMargin1Col`}>
          <div className={`pt32 pb16 `}>
            <Link to={'/illustration-gallery/'} className={`h5`}>
              <i
                className={`inlineIcon mr8`}
                style={{ transform: 'rotate(90deg)' }}
              >
                <ArrowIcon />
              </i>
              All Illustrations
            </Link>
          </div>

          <h1 className={`h3 pb32 textColorFuzzy`}>{illustration_title}</h1>
          <img
            src={illustration_still.localFile.publicURL}
            alt={illustration_still.alternativeText}
          />
          {/* <CustomImg
            localFile={illustration_still.localFile}
            alt={illustration_still.alternativeText}
            captionData={{
              caption: data.strapiIllustration.illustration_caption,
            }}
          /> */}
          <figcaption className={`pt16 pb32`}>
            <p className={`figcaption accentBar pl8 mt8`}>
              {illustration_caption}
            </p>
          </figcaption>

          <div
            className={`mt32 pb64 pt48`}
            style={{ borderTop: '4px solid #e7a263' }}
          >
            <p className={`h5 pb40`}>
              From the Under One Flag Online Exhibition
            </p>
            <Link to={sectionLink}>
              <p className={`h5 textColorWhite`}>
                <span className={`accentText`}>
                  {sectionChapter.subtitle}:{' '}
                </span>
                {sectionChapter.title}
              </p>
              <p className={`h3 textColorWhite block`}>
                {section.section_title}
              </p>
              <span className={`h5`}>
                View In Section
                <i
                  className={`inlineIcon pl4 pr4`}
                  style={{ transform: 'rotate(-90deg)' }}
                >
                  <ArrowIcon />
                </i>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query IllustrationTemplate($illustration_slug: String!) {
    strapiIllustration(illustration_slug: { eq: $illustration_slug }) {
      illustration_title
      illustration_slug
      illustration_caption
      illustration_still {
        alternativeText
        caption
        localFile {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      section {
        chapter
        section_slug
        section_title
      }
    }
    allStrapiChapter {
      nodes {
        strapiId
        chapter_slug
        title
        subtitle
      }
    }
  }
`

export default illustration
